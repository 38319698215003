import { cancelOrder } from '@/api/order.service';
import CancelReasonsList from '@/components/CancelReasonsList';
import { PageTitle } from '@/components/PageTitle';
import { useLocalApp } from '@/hooks/useLocalApp';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { CANCEL_REASONS_LIST, REFUND_LOCATIONS } from '@/lib/helpers/constants';
import { Button } from '@flowardco/ui/Button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputField } from '@flowardco/fui-input-field';
import { Modal } from '@flowardco/fui-modal';

const OrderCancellation = () => {
  const { __T } = useLocalTranslator();
  const { appState } = useLocalApp();
  const navigate = useNavigate();

  const [selectedCancelReason, setSelectedCancelReason] = useState<{
    id: number;
    label: string | undefined;
  }>({
    id: 0,
    label: '',
  });
  const [selectedRefundLocation, setSelectedRefundLocation] = useState<{
    id: number;
    label: string | undefined;
  }>(REFUND_LOCATIONS[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [showOtherReason, setShowOtherReason] = useState(false);
  const [refundToWallet, setRefundToWallet] = useState(true);
  const [otherReason, setOtherReason] = useState('');
  const [showCancelModal, setShowCancelModal] = useState(false);
  const handleCancelReasonClick = (reason: any) => {
    setSelectedCancelReason(reason);
    if (reason.label.toLowerCase() === 'other') {
      setShowOtherReason(true);
      return;
    }
    setOtherReason('');
    setShowOtherReason(false);
  };

  const handleRefundLocationClick = (location: any) => {
    if (location.id !== 1) {
      setRefundToWallet(false);
    }
    setSelectedRefundLocation(location);
  };

  const onCancelOrder = () => {
    setShowCancelModal(true);
  };
  const closeModal = () => {
    setShowCancelModal(false);
  };
  const onCancelConfirm = async () => {
    const reason =
      selectedCancelReason.id > 0 ? selectedCancelReason.label : otherReason;
    const values = {
      CreatedBy: appState?.orderData?.order?.userId,
      CreatedByName: 'Customer',
      OpsName: appState?.orderData?.operation?.nickName,
      OrderId: appState?.orderData?.order?.id,
      Reason: reason,
      RefundToWallet: refundToWallet,
      opsId: appState?.orderData?.operation?.id,
      token: appState?.orderToken,
      countryId: appState?.orderData?.operation?.countryId,
      countryName: appState?.orderData?.operation?.countryName,
      orderPriority: appState?.orderData?.order?.orderPriority,
    };
    try {
      setIsLoading(true);
      const res = await cancelOrder(values, appState, __T);
      if (res) {
        navigate(`/${appState?.lang || 'en'}`);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const isReasonSelected =
    selectedCancelReason.id > 0 || (showOtherReason && otherReason.length > 0);
  return (
    <div>
      <PageTitle title={__T('Order Cancellation')} showBackButton />
      <div className='fui-py-4'>
        <h3 className='fui-mb-8px fui-text-lg fui-font-medium fui-text-theme-neutral-800'>
          {__T('Please select reason')}
        </h3>
        <CancelReasonsList
          __T={__T}
          reasons={CANCEL_REASONS_LIST}
          handleReasonClick={handleCancelReasonClick}
          selectedReason={selectedCancelReason}
        />
        {showOtherReason && (
          <InputField
            placeholder={__T('Please write your reason*')}
            onChange={(e) => setOtherReason(e.target.value)}
          />
        )}
        <h3 className='fui-mb-8px fui-mt-4 fui-text-lg fui-font-medium fui-text-theme-neutral-800'>
          {__T('Where would you like to refund?')}
        </h3>
        <CancelReasonsList
          __T={__T}
          reasons={REFUND_LOCATIONS}
          handleReasonClick={handleRefundLocationClick}
          selectedReason={selectedRefundLocation}
          name={'refund'}
        />
        <Button
          label={__T('Confirm')}
          onClick={onCancelOrder}
          className='fui-mt-3 fui-w-full '
          id='card_button'
          disabled={!isReasonSelected || selectedRefundLocation.id <= -1}
        />

        {showCancelModal ? (
          <Modal
            onIconClose={closeModal}
            onBackdropClose={closeModal}
            className='fui-top-[150px] fui-max-w-[90vw] fui-px-20px fui-py-50px'
          >
            <h1 className='fui-mb-[45px] fui-text-xl fui-font-medium'>
              {__T('Are you sure you want to cancel the order?')}
            </h1>

            <div className='fui-mt-4 fui-flex fui-justify-center fui-gap-3 fui-pt-2'>
              <Button
                buttonStyle='primary'
                type='button'
                onClick={onCancelConfirm}
                isProcessing={isLoading}
              >
                {__T('Confirm')}
              </Button>
              <Button
                buttonStyle='outlinePrimary'
                type='submit'
                onClick={closeModal}
              >
                {__T('Cancel')}
              </Button>
            </div>
          </Modal>
        ) : null}
      </div>
    </div>
  );
};

export default OrderCancellation;
